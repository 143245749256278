<template>
  <v-row justify="center">
    <v-dialog
        v-model="modal"
        scrollable
        max-width="550"
        justify="center"
        content-class="trip-sheet-modal"
        @click:outside="cancelEdit"
        v-if="modal"
    >
      <h3>Update Trip Sheet Image</h3>
<!--      <div class="image-view">-->
<!--        <img :src="fileUrl === '' ? this.trip.tripSheet : fileUrl" class="image" @click="viewImage()">-->
<!--      </div>-->
      <div class="sheet-image-container">
        <v-file-input
            v-model="file"
            accept="image/*"
            color="deep-purple-accent-4"
            counter
            label="Trip Sheet"
            placeholder="Select your files"
            prepend-icon="mdi-paperclip"
            variant="outlined"
            @change="selectFiles"
        >
        </v-file-input>
      </div>
      <div class="button-blk">
        <v-btn color="#facc15" @click="uploadFile" :disabled="fileUrl === ''">Upload Trip Sheet</v-btn>
        <v-btn color="#dbdbdb" @click="cancelEdit">Cancel</v-btn>
      </div>
    </v-dialog>
    <Loading v-if="loading"></Loading>
    <confirm ref="confirm"></confirm>

    <vue-easy-lightbox
        :visible="visible"
        :imgs="images"
        @hide="hideImage"
    >

    </vue-easy-lightbox>
  </v-row>
</template>

<script>
import Loading from "@/components/common/Loading";
import Confirm from "@/components/common/Confirm";
import axios from "@/axios/axios-global";

export default {
  name: "TripSheetUpload",
  components: {Confirm, Loading},
  props: {
    trip: [Object, String],
    open: Boolean,
  },
  data() {
    return {
      modal: this.open,
      file: null,
      fileUrl: '',

      loading: false,

      visible: false,
      images: [],
    }
  },
  methods: {
    cancelEdit() {
      this.$emit('on-cancel')
      this.file = null
      this.fileUrl = ''
    },
    selectFiles() {
      if (this.file && this.isImage(this.file)) {
          this.fileUrl = URL.createObjectURL(this.file);
      } else {
          this.$toasted.show('Please select an image file', {
              position: "bottom-center",
              duration: 4000
          });
      }
    },
    isImage(file) {
        return file && file['type'].split('/')[0] === 'image';
    },
    async uploadFile() {
      let confirmTitle = "Confirm Trip Sheet Upload";
      let confirmText = "Please confirm to upload trip sheet";
      if (await this.$refs.confirm.open(confirmTitle, confirmText)) {
        // this.loading = true
        try {
          const payload = {
            tripId: this.trip.bigId,
          }
          console.log(payload)
          const formData = new FormData()
          formData.append('data', JSON.stringify(payload))
          formData.append('image', this.file)
          const response = await axios.patch('/backend/tripSheetInsert', formData)
          console.log(response)
          // this.loading = false
          if (response.status === 200) {
            this.$emit('on-upload')
            this.file = null
            this.fileUrl = ''
            setTimeout(() => {
            this.$toasted.show(response.data.message, {
              position: "bottom-center",
              duration: 4000
            });
            }, 500)
          } else {
            this.loading = true
            setTimeout(() => {
              this.loading = false
            }, 500)
          }
        } catch (error) {
          this.loading = true
          setTimeout(() => {
            this.loading = false
          }, 500)
        }
      }

    },

    hideImage() {
      this.visible = false
    },

    viewImage() {
      this.images = []
      this.images.push(this.fileUrl === '' ? this.trip.tripSheet : this.fileUrl)
      // this.openIndex = (this.images.length > index ? index : 0);
      this.visible = true
    },
  },
  watch: {
    file() {
      if (this.file === null) {
        this.fileUrl = ''
      }
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .trip-sheet-modal {
  background: #FFF;
  padding: 20px 20px;
  width: 100%;
  max-height: calc(100vh - 200px);
  overflow-y: scroll;
  display: flex;
  flex-direction: column;

  h3 {
    font-weight: bold;
    color: grey;
    text-align: center;
    font-size: 1.5em;
    border-bottom: 1px solid #d6d6d6;
    padding-bottom: 5px;
  }

  .image-view {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px;

    .image {
      max-width: 100%;
      max-height: 300px;
      object-fit: cover;
    }
  }

  .sheet-image-container {
    max-height: 300px;
    width: 100%;
    text-align: center;
    vertical-align: middle;
    padding: 10px;

    span {
      font-weight: bold;
      color: grey;
    }
  }

  .button-blk {
    display: flex;
    justify-content: center;
    padding: 10px 0;
    column-gap: 10px;

  }
}
</style>