import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'

import VueRouter from "vue-router";

import '@fortawesome/fontawesome-free/css/all.min.css';

import "@mdi/font/css/materialdesignicons.css";

import {routes} from "@/router";

import Toasted from 'vue-toasted';
import instance from "@/axios/axios-global";

import toast from "@/components/common/toast";

import Lightbox from 'vue-easy-lightbox'

import {VueUtil} from './components/common/VueUtils'

Vue.mixin(VueUtil);
import VueExcel from './components/common/VueExcel'

Vue.prototype.$VueExcel = VueExcel;
Vue.component('VueExcel', VueExcel);

Vue.config.productionTip = false

Vue.use(VueRouter)
Vue.use(Toasted)
Vue.use(Lightbox)

const router = new VueRouter({
    mode: 'history',
    base: '/',
    routes
});

router.beforeEach(
    (to, from, next) => {
        let retrievedObject = localStorage.getItem('vendorToken');
        let vendorLoginStatus = localStorage.getItem('vendorStatus')
        if (retrievedObject === null) {
            if (to.path === '/login') {
                console.log('already in login page')
                next()
            } else {
                router.push('/login')
                next()
            }
        } else {
            // if(to.path)
            if (to.path === '/login') {
                router.push('/dashboard')
                next()
            } else if (to.path === '/') {
                router.push('/dashboard')
                next()
            }
            next()
        }

    }
)

instance.interceptors.response.use((response) => {

    // if (response.status === 200) {
    //     if(response.data.message) {
    //         toast.success(response.data.message)
    //     }
    // }
    // else if (response.status === 206) {
    //     if(response.data) {
    //         toast.success(response.data)
    //     }
    // }
    //     instance.defaults.headers.common['token'] = "";
    //
    //     localStorage.removeItem('vendorToken')
    //     localStorage.removeItem('vendorName')
    //     localStorage.removeItem('vendorNumber')
    //     localStorage.removeItem('vendorStatus')
    //
    //     this.$router.push('/login')
    // }
    return response;
}, (error) => {

    if (error.response.status === 401) {
        instance.defaults.headers.common['token'] = "";

        localStorage.removeItem('vendorToken')
        localStorage.removeItem('vendorName')
        localStorage.removeItem('vendorNumber')
        localStorage.removeItem('vendorStatus')
        localStorage.removeItem('menuOpened')

        router.push('/login')
    }
    // else {
    //     if(error) {
    //         console.log(error)
    //         toast.error('Error' + '-' + error.response.data)
    //     }
    // }
})

new Vue({
    vuetify,
    router,
    render: function (h) {
        return h(App)
    }
}).$mount('#app')
