<template>
  <div style="padding: 10px; background-color: #d4d4d4">
    <!-- <v-row no-gutters>
        <v-col cols="6" sm="6" style="display: flex; align-items: center; justify-content: center">
          <div style="width: 100%; padding: 5px 30px 15px 0px">
            <v-select :items="fare_types"  item-value="value" item-text="text" label="Fare Type*" v-model="fare_type_id" @change="getFares()" outlined dense hide-details />
          </div>
        </v-col>
        <v-col cols="6" sm="6" style="display: flex; align-items: center">
          <v-radio-group row v-model="fare_type" @change="fare_type_dialog = !fare_type_dialog">
            <v-radio label="Automatic" :value="1"></v-radio>
            <v-radio label="Manual" :value="2"></v-radio>
          </v-radio-group>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon small color="#eea004" v-on="on" style="margin: 0px 5px"> fas fa-info-circle </v-icon>
            </template>
            <div>
              <p>modified by: {{ this.modified.modified_by }}</p>
              <p>modified at: {{ this.modified.modified_at }}</p>
            </div>
          </v-tooltip>
          <v-dialog v-model="fare_type_dialog" v-if="fare_type_dialog">
            <v-card style="color: black">
              <v-card-title style="font-weight: 800">Confirmation</v-card-title>
              <v-card-text style="padding: 5px 24px 5px 24px"
                >Are you sure, do you want to update the fare type to <span style="font-weight: 800; color: black">"{{ fare_type == 1 ? "Automatic" : "Manual" }}"</span>?</v-card-text
              >
              <v-card-actions style="display: flex; justify-content: flex-end">
                <v-btn small dark @click="closeDialog()" style="margin: 10px">Cancel</v-btn>
                <v-btn small dark @click="updateFareType()" style="margin: 10px" color="#eea004">Agree</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" small class="sync-icon" @click="getFares(), getDrivers()">fas fa-sync-alt</v-icon>
            </template>
            <div>Sync</div>
          </v-tooltip>
        </v-col>
      </v-row> -->
    <v-card style="padding: 20px; margin: 20px 10px">
      <v-card-text>
        <!-- <div class="updated-by" v-if="updated && !loading">
          <p>{{ updated.updated_at }} | {{ updated.updated_by }}</p>
        </div> -->
        <v-row>
          <v-col cols="3">
            <v-select :items="trip_types" item-value="value" item-text="text" label="Trip Type *" v-model="trip_type" @change="setTripSubTypes()" outlined dense hide-details />
          </v-col>
          <v-col cols="2">
            <v-select :items="trip_sub_types" item-value="value" item-text="text" :label="label + ' *'" v-model="trip_sub_type" @change="setFares()" outlined dense hide-details />
          </v-col>
          <v-col cols="2">
            <v-select :items="hours" item-value="value" item-text="text" label="Hours *" v-model="hour" outlined dense hide-details />
          </v-col>
          <v-col cols="2">
            <v-text-field
              label="Toll *"
              dense
              outlined
              v-model.number="toll"
              hide-details
              onkeypress="return /[0-9.]/i.test(event.key)"
              @input="$v.toll.$touch()"
              @blur="$v.toll.$touch()"
              :error-messages="requiredError('toll', 'Toll', $v)"></v-text-field>
          </v-col>
          <v-col cols="3" v-if="is_fare_exist && !loading">
            <v-select :items="buffer_times" item-text="name" hide-details item-value="minutes" v-model="buffer_time" label="Buffer Time" outlined dense />
          </v-col>
          <!-- <v-col cols="2" v-if="trip_type === 4">
            <v-select :items="update_types" item-value="value" item-text="text" label="Update Type *" v-model="update_type" outlined dense hide-details />
          </v-col> -->
        </v-row>
        <v-skeleton-loader :loading="loading" type="card" v-if="loading"></v-skeleton-loader>
        <v-row v-if="!loading" style="margin-top: 10px">
          <div v-if="is_fare_exist" class="fare-container">
            <v-row no-gutters>
              <v-row v-if="!loading && update_type === 1">
                <v-col cols="12">
                  <v-select :items="buffer_times" item-text="name" hide-details item-value="minutes" v-model="buffer_time" label="Buffer Time" outlined dense />
                </v-col>
              </v-row>
              <v-card class="vehicle" v-if="update_type !== 3">
                <v-row>
                  <v-col cols="12">
                    <v-select :items="vehicle_types" item-value="value" hide-details item-text="text" label="Vehicle Type *" v-model="vehicle_type" @change="setFares()" outlined dense />
                  </v-col>
                </v-row>
                <v-row v-if="update_type === 1">
                  <v-col cols="12" sm="5">
                    <div class="combustion-types">
                      <v-checkbox v-for="type of fares.combustion_types" :key="type.id" :label="type.type" :value="type.value" v-model="type.value" hide-details>
                        {{ type.type }}
                      </v-checkbox>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="7" class="cancellation-policy">
                    <v-select :items="cancellation_types" hide-details item-text="name" item-value="name" v-model="fares.cancellation_type" label="Cancellation Policy" outlined dense>
                      <template v-slot:[`item`]="{ item }">
                        <v-list>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title
                                ><b>{{ item.name }}</b></v-list-item-title
                              >
                              <v-list-item-subtitle style="padding-right: 40px">{{ item.desc }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
                <div v-if="update_type === 1">
                  <v-row v-if="trip_type === 4">
                    <v-col cols="4">
                      <v-text-field
                        label="No of Vehicles Available *"
                        dense
                        outlined
                        hide-details
                        v-model.number="fares.no_of_vehicles"
                        onkeypress="return /[0-9.]/i.test(event.key)"
                        @input="$v.fares.no_of_vehicles.$touch()"
                        @blur="$v.fares.no_of_vehicles.$touch()"
                        :error-messages="requiredError('no_of_vehicles', 'No of vehicles', $v.fares)"></v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        label="Price per km *"
                        dense
                        outlined
                        hide-details
                        v-model.number="fares.priceprkm"
                        onkeypress="return /[0-9.]/i.test(event.key)"
                        @input="$v.fares.priceprkm.$touch()"
                        @blur="$v.fares.priceprkm.$touch()"
                        :error-messages="requiredError('priceprkm', 'Price per km', $v.fares)"></v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        label="Extra km price(not included in calculations) *"
                        dense
                        outlined
                        hide-details
                        v-model.number="fares.extraprkm"
                        onkeypress="return /[0-9.]/i.test(event.key)"
                        @input="$v.fares.extraprkm.$touch()"
                        @blur="$v.fares.extraprkm.$touch()"
                        :error-messages="requiredError('extraprkm', 'Extra Price per km', $v.fares)"></v-text-field>
                    </v-col>
                    <v-col :cols="fare_type_id === 1 ? 4 : 8">
                      <v-text-field
                        label="0 to 10 kms *"
                        dense
                        outlined
                        v-model.number="fares.below_10.price"
                        onkeypress="return /[0-9.]/i.test(event.key)"
                        @input="$v.fares.below_10.price.$touch()"
                        @blur="$v.fares.below_10.price.$touch()"
                        :error-messages="requiredError('price', 'Fare', $v.fares.below_10)"></v-text-field>
                    </v-col>
                    <v-col cols="4" v-if="fare_type_id === 1">
                      <v-text-field
                        label="Min Fare (0 to 10 kms) *"
                        dense
                        outlined
                        v-model.number="fares.below_10.min_fare"
                        onkeypress="return /[0-9.]/i.test(event.key)"
                        @input="$v.fares.below_10.min_fare.$touch()"
                        @blur="$v.fares.below_10.min_fare.$touch()"
                        :error-messages="requiredError('min_fare', 'Fare', $v.fares.below_10)"></v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-radio-group row hide-details v-model="fares.below_10.inventory" style="margin: 0px">
                        <v-radio label="On" :value="true"></v-radio>
                        <v-radio label="Off" :value="false"></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col :cols="fare_type_id === 1 ? 4 : 8">
                      <v-text-field
                        label="10 to 20 kms *"
                        dense
                        outlined
                        v-model.number="fares.above_10.price"
                        onkeypress="return /[0-9.]/i.test(event.key)"
                        @input="$v.fares.above_10.price.$touch()"
                        @blur="$v.fares.above_10.price.$touch()"
                        :error-messages="requiredError('price', 'Fare', $v.fares.above_10)"></v-text-field>
                    </v-col>
                    <v-col cols="4" v-if="fare_type_id === 1">
                      <v-text-field
                        label="Min Fare (10 to 20 kms) *"
                        dense
                        outlined
                        v-model.number="fares.above_10.min_fare"
                        onkeypress="return /[0-9.]/i.test(event.key)"
                        @input="$v.fares.above_10.min_fare.$touch()"
                        @blur="$v.fares.above_10.min_fare.$touch()"
                        :error-messages="requiredError('min_fare', 'Fare', $v.fares.above_10)"></v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-radio-group row hide-details v-model="fares.above_10.inventory" style="margin: 0px">
                        <v-radio label="On" :value="true"></v-radio>
                        <v-radio label="Off" :value="false"></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col :cols="fare_type_id === 1 ? 4 : 8">
                      <v-text-field
                        label="21 to 30 kms *"
                        dense
                        outlined
                        v-model.number="fares.above_20.price"
                        onkeypress="return /[0-9.]/i.test(event.key)"
                        @input="$v.fares.above_20.price.$touch()"
                        @blur="$v.fares.above_20.price.$touch()"
                        :error-messages="requiredError('price', 'Fare', $v.fares.above_20)"></v-text-field>
                    </v-col>
                    <v-col cols="4" v-if="fare_type_id === 1">
                      <v-text-field
                        label="Min Fare (21 to 30 kms) *"
                        dense
                        outlined
                        v-model.number="fares.above_20.min_fare"
                        onkeypress="return /[0-9.]/i.test(event.key)"
                        @input="$v.fares.above_20.min_fare.$touch()"
                        @blur="$v.fares.above_20.min_fare.$touch()"
                        :error-messages="requiredError('min_fare', 'Fare', $v.fares.above_20)"></v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-radio-group row hide-details v-model="fares.above_20.inventory" style="margin: 0px">
                        <v-radio label="On" :value="true"></v-radio>
                        <v-radio label="Off" :value="false"></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col :cols="fare_type_id === 1 ? 4 : 8">
                      <v-text-field
                        label="31 to 40 kms *"
                        dense
                        outlined
                        v-model.number="fares.above_30.price"
                        onkeypress="return /[0-9.]/i.test(event.key)"
                        @input="$v.fares.above_30.price.$touch()"
                        @blur="$v.fares.above_30.price.$touch()"
                        :error-messages="requiredError('price', 'Fare', $v.fares.above_30)"></v-text-field>
                    </v-col>
                    <v-col cols="4" v-if="fare_type_id === 1">
                      <v-text-field
                        label="Min Fare (31 to 40 kms) *"
                        dense
                        outlined
                        v-model.number="fares.above_30.min_fare"
                        onkeypress="return /[0-9.]/i.test(event.key)"
                        @input="$v.fares.above_30.min_fare.$touch()"
                        @blur="$v.fares.above_30.min_fare.$touch()"
                        :error-messages="requiredError('min_fare', 'Fare', $v.fares.above_30)"></v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-radio-group row hide-details v-model="fares.above_30.inventory" style="margin: 0px">
                        <v-radio label="On" :value="true"></v-radio>
                        <v-radio label="Off" :value="false"></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col :cols="fare_type_id === 1 ? 4 : 8">
                      <v-text-field
                        label="41 to 50 kms *"
                        dense
                        outlined
                        v-model.number="fares.above_40.price"
                        onkeypress="return /[0-9.]/i.test(event.key)"
                        @input="$v.fares.above_40.price.$touch()"
                        @blur="$v.fares.above_40.price.$touch()"
                        :error-messages="requiredError('price', 'Fare', $v.fares.above_40)"></v-text-field>
                    </v-col>
                    <v-col cols="4" v-if="fare_type_id === 1">
                      <v-text-field
                        label="Min Fare (41 to 50 kms) *"
                        dense
                        outlined
                        v-model.number="fares.above_40.min_fare"
                        onkeypress="return /[0-9.]/i.test(event.key)"
                        @input="$v.fares.above_40.min_fare.$touch()"
                        @blur="$v.fares.above_40.min_fare.$touch()"
                        :error-messages="requiredError('min_fare', 'Fare', $v.fares.above_40)"></v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-radio-group row hide-details v-model="fares.above_40.inventory" style="margin: 0px">
                        <v-radio label="On" :value="true"></v-radio>
                        <v-radio label="Off" :value="false"></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col :cols="fare_type_id === 1 ? 4 : 8">
                      <v-text-field
                        label="51 to 60 kms *"
                        dense
                        outlined
                        v-model.number="fares.above_50.price"
                        onkeypress="return /[0-9.]/i.test(event.key)"
                        @input="$v.fares.above_50.price.$touch()"
                        @blur="$v.fares.above_50.price.$touch()"
                        :error-messages="requiredError('price', 'Fare', $v.fares.above_50)"></v-text-field>
                    </v-col>
                    <v-col cols="4" v-if="fare_type_id === 1">
                      <v-text-field
                        label="Min Fare (51 to 60 kms) *"
                        dense
                        outlined
                        v-model.number="fares.above_50.min_fare"
                        onkeypress="return /[0-9.]/i.test(event.key)"
                        @input="$v.fares.above_50.min_fare.$touch()"
                        @blur="$v.fares.above_50.min_fare.$touch()"
                        :error-messages="requiredError('min_fare', 'Fare', $v.fares.above_50)"></v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-radio-group row hide-details v-model="fares.above_50.inventory" style="margin: 0px">
                        <v-radio label="On" :value="true"></v-radio>
                        <v-radio label="Off" :value="false"></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col :cols="fare_type_id === 1 ? 4 : 8">
                      <v-text-field
                        label="60+ kms *"
                        dense
                        outlined
                        v-model.number="fares.above_60.price"
                        onkeypress="return /[0-9.]/i.test(event.key)"
                        @input="$v.fares.above_60.price.$touch()"
                        @blur="$v.fares.above_60.price.$touch()"
                        :error-messages="requiredError('price', 'Fare', $v.fares.above_60)"></v-text-field>
                    </v-col>
                    <v-col cols="4" v-if="fare_type_id === 1">
                      <v-text-field
                        label="Min Fare (60+ kms) *"
                        dense
                        outlined
                        v-model.number="fares.above_60.min_fare"
                        onkeypress="return /[0-9.]/i.test(event.key)"
                        @input="$v.fares.above_60.min_fare.$touch()"
                        @blur="$v.fares.above_60.min_fare.$touch()"
                        :error-messages="requiredError('min_fare', 'Fare', $v.fares.above_60)"></v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-radio-group row hide-details v-model="fares.above_60.inventory" style="margin: 0px">
                        <v-radio label="On" :value="true"></v-radio>
                        <v-radio label="Off" :value="false"></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <v-row v-if="trip_type === 2">
                    <v-col cols="12">
                      <v-text-field
                        label="No of Vehicles Available *"
                        dense
                        outlined
                        v-model.number="outstation_fares.no_of_vehicles"
                        onkeypress="return /[0-9.]/i.test(event.key)"
                        @input="$v.outstation_fares.no_of_vehicles.$touch()"
                        @blur="$v.outstation_fares.no_of_vehicles.$touch()"
                        :error-messages="requiredError('no_of_vehicles', 'No of vehicles', $v.outstation_fares)"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        label="Minimum Kms *"
                        dense
                        outlined
                        v-model.number="outstation_fares.min_kms"
                        onkeypress="return /[0-9.]/i.test(event.key)"
                        @input="$v.outstation_fares.min_kms.$touch()"
                        @blur="$v.outstation_fares.min_kms.$touch()"
                        :error-messages="requiredError('min_kms', 'Minimum Kms', $v.outstation_fares)"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        label="Price *"
                        dense
                        outlined
                        v-model.number="outstation_fares.price"
                        onkeypress="return /[0-9.]/i.test(event.key)"
                        @input="$v.outstation_fares.price.$touch()"
                        @blur="$v.outstation_fares.price.$touch()"
                        :error-messages="requiredError('price', 'Price', $v.outstation_fares)"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        label="Maximum Kms *"
                        dense
                        outlined
                        v-model.number="outstation_fares.max_kms"
                        onkeypress="return /[0-9.]/i.test(event.key)"
                        @input="$v.outstation_fares.max_kms.$touch()"
                        @blur="$v.outstation_fares.max_kms.$touch()"
                        :error-messages="requiredError('max_kms', 'Maximum Kms', $v.outstation_fares)"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        label="Price per km *"
                        dense
                        outlined
                        v-model.number="outstation_fares.priceprkm"
                        onkeypress="return /[0-9.]/i.test(event.key)"
                        @input="$v.outstation_fares.priceprkm.$touch()"
                        @blur="$v.outstation_fares.priceprkm.$touch()"
                        :error-messages="requiredError('priceprkm', 'Price per km', $v.outstation_fares)"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        label="Extra km price(not included in calculations) *"
                        dense
                        outlined
                        v-model.number="outstation_fares.extraprkm"
                        onkeypress="return /[0-9.]/i.test(event.key)"
                        @input="$v.outstation_fares.extraprkm.$touch()"
                        @blur="$v.outstation_fares.extraprkm.$touch()"
                        :error-messages="requiredError('extraprkm', 'Extra km price', $v.outstation_fares)"></v-text-field>
                    </v-col>
                  </v-row>
                </div>
                <div v-if="update_type === 2">
                  <v-row class="slab-percentage">
                    <v-card v-for="(day, d) of days" :key="day.text" :id="'day' + d">
                      <v-card-title @click="openSlab(d)">
                        <div class="label">
                          <span>{{ day.text.slice(0, 10) }}</span>
                          <span>{{ ` | From Airport: ${getTripCount(day.text).type_1_tc} trips | To Airport: ${getTripCount(day.text).type_2_tc} trips` }} </span>
                        </div>
                        <div>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-icon small color="#eea004" v-on="on" style="margin: 0px 5px"> fas fa-info-circle </v-icon>
                            </template>
                            <v-row>
                              <v-col cols="6">
                                <div class="tooltip-label">From Airport</div>
                                <ul>
                                  <li v-for="data of getTripCount(day.text).result" style="white-space: nowrap" :key="data.time">
                                    {{ `${data.time} : ${data.type_1_count}` }}
                                  </li>
                                  <hr />
                                  <div style="text-align: end">
                                    {{ `total : ${getTripCount(day.text).type_1_tc}` }}
                                  </div>
                                </ul>
                              </v-col>
                              <v-col cols="6">
                                <div class="tooltip-label">To Airport</div>
                                <ul>
                                  <li v-for="data of getTripCount(day.text).result" style="white-space: nowrap" :key="data.time">
                                    {{ `${data.time} : ${data.type_2_count}` }}
                                  </li>
                                  <hr />
                                  <div style="text-align: end">
                                    {{ `total : ${getTripCount(day.text).type_2_tc}` }}
                                  </div>
                                </ul>
                              </v-col>
                            </v-row>
                          </v-tooltip>
                          <v-icon small color="#eea004" :class="open_slab === d ? 'arrow' : ''">fas fa-angle-down</v-icon>
                        </div>
                      </v-card-title>
                      <v-card-text v-if="open_slab === d">
                        <div class="sub">
                          <div v-for="(kms_slab, k) of day.kms_slabs" :key="kms_slab.value" :id="'kms_slab' + k" class="sub">
                            <div class="label">{{ kms_slab.text }}</div>
                            <v-row>
                              <v-col cols="3" v-for="(time_slab, t) of kms_slab.time_slabs" :key="time_slab.from_time" :id="'time_slab' + t" :class="getErrorClass(time_slab.percentage)">
                                <div v-if="fare_type_id === 1">
                                  <v-text-field
                                    :label="time_slab.from_time + ' to ' + time_slab.to_time"
                                    dense
                                    outlined
                                    readonly
                                    maxlength="3"
                                    hide-details
                                    v-model.number="time_slab.min_price"></v-text-field>
                                </div>
                                <div v-else>
                                  <v-text-field
                                    :label="time_slab.from_time + ' to ' + time_slab.to_time"
                                    dense
                                    outlined
                                    maxlength="3"
                                    hide-details
                                    v-model.number="time_slab.percentage"
                                    onkeypress="return /[0-9-]/i.test(event.key)"></v-text-field>
                                  <span class="price-info" v-if="!getErrorClass(time_slab.percentage)">{{
                                    `op : ${getFareDetails(time_slab.percentage, kms_slab.value).orginal_price} | mp : ${getFareDetails(time_slab.percentage, kms_slab.value).modified_price}`
                                  }}</span>
                                  <span v-else style="color: red">invalid percentage</span>
                                </div>
                              </v-col>
                            </v-row>
                          </div>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-row>
                  <div class="note-section">*Note: The trip counts mentioned above are a combination of all vehicle types, not specific to any single type.</div>
                </div>
              </v-card>
              <v-card v-if="update_type === 3" class="hourly-restriction">
                <v-row>
                  <v-checkbox v-for="item of week_days" :key="item.week_day" :label="item.text" :value="item.value" style="color: #870947" v-model="item.value" hide-details class="week-days">
                  </v-checkbox>
                </v-row>
              </v-card>
            </v-row>
          </div>
          <div class="no-fare" v-else>
            No fare details found.
            <v-btn style="margin-bottom: 10px; color: #ffffff" small @click="navigate()"> Add Fare </v-btn>
          </div>
        </v-row>
      </v-card-text>
      <v-card-actions class="action-btn" v-if="is_fare_exist && !loading">
        <v-btn color="#eea004" style="margin-bottom: 10px; color: white" size="small" @click="updateFares()"> Update </v-btn>
      </v-card-actions>
    </v-card>
    <v-card class="instant-inventory-card" v-if="instant && is_fare_exist">
      <v-skeleton-loader :loading="loading" type="card" v-if="loading"></v-skeleton-loader>
      <v-card-text v-if="!loading">
        <!-- <div class="updated-by" v-if="!loading">
          <p>{{ instant.modified_at }} | {{ instant.modified_by }}</p>
        </div> -->
        <v-row class="instant-container">
          <v-col cols="12" sm="3" style="padding-top: 0px">
            <span>Instant Inventory</span>
            <v-radio-group row v-model="instant.is_instant" hide-details style="margin: 0px">
              <v-radio label="On" :value="true"></v-radio>
              <v-radio label="Off" :value="false"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="12" sm="4">
            <v-autocomplete
              v-model="driver"
              :items="drivers"
              item-text="name"
              hide-details
              placeholder="Search"
              label="Drivers"
              dense
              outlined
              item-value="token"
              @change="addDriver()"
              clearable
              v-bind:search-input.sync="search">
              <!-- <template v-slot:[`item`]="{ item }">
                <v-list class="driver-list">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title
                        ><b>{{ item.name }}</b></v-list-item-title
                      >
                      <v-list-item-subtitle :style="item.onlinestatus ? 'color:#44d644' : 'color:red'">
                        {{ `${item.mobile} ${item.onlinestatus ? " - Online" : " - Offline"}` }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </template> -->
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field label="Radius *" dense outlined v-model.number="instant.radius" hide-details onkeypress="return /[0-9.]/i.test(event.key)"></v-text-field>
          </v-col>
          <v-col cols="12" sm="3" v-if="trip_sub_type === 2">
            <v-autocomplete :items="vendors" v-model="vendor_id" label="Vendor" hide-details="true" item-text="name" item-value="id" dense outlined clearable></v-autocomplete>
          </v-col>
        </v-row>
        <v-row v-if="instant.drivers && instant.drivers.length > 0" class="driver-list">
          <v-chip-group column>
            <v-chip v-for="(item, index) of instant.drivers" :key="item.token"
              >{{ item.name }}
              <v-icon small color="red" @click="removeDriver(index)">far fa-times-circle</v-icon>
            </v-chip>
          </v-chip-group>
        </v-row>
      </v-card-text>
      <v-card-actions class="action-btn" v-if="is_fare_exist && !loading">
        <v-btn color="#eea004" style="color: white; margin-bottom: 10px" size="small" @click="updateInstantSearch()"> Update </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import axios from "axios";
import json_data from "./data.json";
import moment from "moment-timezone";
export default {
  props: {
    corporateId: Number,
  },
  data() {
    return {
      user: null,
      hours: json_data.hours,
      hour_slabs: [],
      hour: 1,
      toll: 0,
      instant: {
        is_instant: false,
        radius: 1,
      },
      days: [],
      fares_list: [],
      is_fare_exist: false,
      add_dialog: false,
      fares: json_data.airport_fares,
      outstation_fares: json_data.outstation_fares,
      loading: false,
      trip_types: json_data.trip_types,
      trip_type: 4,
      trip_sub_types: json_data.trip_sub_types,
      instant_trip_sub_types: [
        {
          text: "From Airport",
          value: 1,
        },
        {
          text: "To Airport",
          value: 2,
        },
        {
          text: "Both",
          value: 3,
        },
      ],
      trip_sub_type: 1,
      label: "Pickup Type",
      vehicle_types: json_data.vehicle_types,
      vehicle_type: 1,
      update_types: json_data.update_types,
      update_type: 1,
      open_slab: 0,
      time_index: null,
      day_index: null,
      kms_index: null,
      is_error: false,
      trips: [],
      fare_type_id: 2,
      fare_type: null,
      fare_type_dialog: false,
      fareTypeConfirmation: false,
      fare_types: json_data.fare_types,
      cancellation_types: json_data.cancellation_types,
      cancellation_type: null,
      buffer_times: json_data.buffer_times,
      buffer_time: null,
      modified: {},
      updated: {},
      week_days: json_data.week_days,
      ivy_time_slabs: json_data.iny_time_slabs,
      driver: null,
      drivers: [],
      search: null,
      baseURL: "https://www.taskicabs.com/adminbackend/",
      corporate_id: 102,
      branch_id: 1477,
      vendorInfo: {
        name: localStorage.getItem("vendorName"),
        token: localStorage.getItem("vendorToken"),
      },
    };
  },
  mixins: [validationMixin],
  validations: {
    trip_type: { required },
    trip_sub_type: { required },
    branch_id: { required },
    hour: { required },
    toll: { required },
    instant: {
      is_instant: { required },
    },
    fares: {
      below_10: {
        price: { required },
        inventory: { required },
      },
      above_10: {
        price: { required },
        inventory: { required },
      },
      above_20: {
        price: { required },
        inventory: { required },
      },
      above_30: {
        price: { required },
        inventory: { required },
      },
      above_40: {
        price: { required },
        inventory: { required },
      },
      above_50: {
        price: { required },
        inventory: { required },
      },
      above_60: {
        price: { required },
        inventory: { required },
      },
      priceprkm: { required },
      extraprkm: { required },
      no_of_vehicles: { required },
    },
    outstation_fares: {
      min_kms: { required },
      max_kms: { required },
      priceprkm: { required },
      extraprkm: { required },
      price: { required },
      no_of_vehicles: { required },
    },
  },
  methods: {
    addDriver() {
      if (!this.driver) return;
      this.search = null;
      let driver = this.drivers.find((d) => d.token === this.driver);
      let is_exist = this.instant.drivers.find((d) => d.token === driver.token);
      if (!is_exist) {
        this.instant.drivers.push({ token: driver.token, name: driver.name, available_from: moment().tz("Asia/Kolkata").valueOf() });
      }
    },
    removeDriver(index) {
      this.instant.drivers.splice(index, 1);
    },
    requiredError(fieldName, label, form) {
      const errors = [];
      const field = form[fieldName];
      if (field && !field.$dirty) return errors;
      field && field.$invalid && errors.push(label + " is required");
      return errors;
    },
    openSlab(d) {
      this.open_slab = this.open_slab === d ? null : d;
    },
    navigate() {
      this.$router.push(`/yatra/add-fare/${this.branch_id}/${this.trip_type}`);
    },
    getFareDetails(percentage, kms_slab) {
      {
        const original_price = this.fares[kms_slab].price;
        const modified_price = Math.floor((original_price * percentage) / 100);
        return {
          orginal_price: original_price,
          modified_price: original_price + modified_price,
        };
      }
    },
    getTripCount(day) {
      let time_slabs = json_data.time_slabs_1;
      day = day.slice(0, 11);
      let result = [];
      let type_1_tc = 0;
      let type_2_tc = 0;
      time_slabs.forEach((time_slab) => {
        const from_time = moment(`${day} ${time_slab.from_time}`, "YYYY-MM-DD HH:mm:ss");
        const to_time = moment(`${day} ${time_slab.to_time}`, "YYYY-MM-DD HH:mm:ss");
        let type_1_count = 0;
        let type_2_count = 0;
        this.trips.filter((trip) => {
          const trip_time = moment(trip.time.pickupdate, "YYYY-MM-DD HH:mm:ss");
          const is_between = trip_time.isBetween(from_time, to_time, null, "[]");
          if (is_between) {
            trip.isAirportTrip === 1 ? (type_1_count += 1) : (type_2_count += 1);
            trip.isAirportTrip === 1 ? (type_1_tc += 1) : (type_2_tc += 1);
          }
        });
        result.push({
          time: `${time_slab.from_time} - ${time_slab.to_time}`,
          type_1_count: type_1_count,
          type_2_count: type_2_count,
        });
      });
      return {
        result: result,
        type_1_tc: type_1_tc,
        type_2_tc: type_2_tc,
        total_trip_count: this.trip_sub_type === 1 ? type_1_tc : type_2_tc,
      };
    },
    getErrorClass(percentage) {
      if (percentage === null || percentage === "" || percentage === undefined || percentage < -99 || percentage > 99) {
        return true;
      } else {
        return false;
      }
    },
    async getDrivers() {
      let request_body = { searchquery: "", isTrash: true, vendorid: 1697, carstatus: 1, order: "desc", orderBy: "registerdate", page: 1, limit: 5000 };
      let response = await axios.post(this.baseURL + "vendor/backend/driverList", request_body, { headers: { token: this.vendorInfo.token } });
      this.drivers = response && response.data ? response.data : [];
    },
    // async getEvVendors() {
    //   this.vendors = [];
    //   let response = await axios.get("web/admin/mmt/getEvVendors");
    //   this.vendors = response.status === 200 && response.data.success ? response.data.vendors : [];
    // },
    validateFares() {
      if (this.trip_type === 4) {
        if (
          !this.fares.priceprkm ||
          !this.fares.extraprkm ||
          !this.fares.below_10.price ||
          !this.fares.above_10.price ||
          !this.fares.above_20.price ||
          !this.fares.above_30.price ||
          !this.fares.above_40.price ||
          !this.fares.above_50.price ||
          !this.fares.above_60.price ||
          this.fares.no_of_vehicles === undefined ||
          this.fares.no_of_vehicles === null ||
          this.fares.no_of_vehicles === ""
        ) {
          this.update_type = 1;
          this.$v.fares.$touch();
          return {
            error: true,
            error_message: "Please enter all the fare details correctly",
          };
        }
        if (this.toll === undefined || this.toll === "" || this.toll === null) {
          this.$v.$touch();
          return {
            error: true,
            error_message: "Please enter the toll amount",
          };
        }
        let error = false;
        let index = null;
        this.days.forEach((day, d) => {
          day.kms_slabs.forEach((kms_slab, k) => {
            kms_slab.time_slabs.forEach((time_slab, t) => {
              if (time_slab.percentage === null || time_slab.percentage === "" || time_slab.percentage === undefined || time_slab.percentage < -99 || time_slab.percentage > 99) {
                this.open_slab = d;
                index = k;
                error = true;
                this.time_index = t;
                this.kms_index = k;
                this.day_index = d;
                return;
              }
            });
            if (error) return;
          });
          if (error) return;
        });
        if (error)
          return {
            error: error,
            error_message: "Please enter all the percentages correctly.",
            index: index,
          };
        return { error: error };
      }
    },
    updateFares() {
      let is_valid = this.validateFares();
      if (is_valid.error) {
        if (is_valid.index) {
          const targetElement = document.getElementById("kms_slab" + is_valid.index);
          targetElement.scrollIntoView({ behavior: "smooth" });
        }
        return this.$toasted.show(is_valid.error_message, {
          position: "bottom-center",
          duration: 3000,
        });
      }
      this.loading = true;
      let request_body = {
        hour: this.hour,
        toll: this.toll,
        fares: this.trip_type === 4 ? this.fares : this.outstation_fares,
        trip_type: this.trip_type,
        trip_sub_type: this.trip_sub_type,
        branch_id: this.branch_id,
        vehicle_type: this.vehicle_type,
        modified_by: this.vendorInfo.name,
        buffer_time: this.buffer_time,
      };
      let api_url = this.fare_type_id === 1 ? this.baseURL + "web/admin/mmt/updateAutoFares" : this.baseURL + "web/admin/mmt/updateFares";
      axios
        .post(api_url, request_body)
        .then((res) => {
          if (res.data && res.data.success) {
            this.$toasted.show(res.data.message, {
              position: "bottom-center",
              duration: 3000,
            });
            this.getFares();
          } else {
            this.$toasted.show("Error: " + res.data.error, {
              position: "bottom-center",
              duration: 3000,
            });
            this.loading = false;
          }
        })
        .catch((error) => {
          this.$toasted.show("Error: " + error, {
            position: "bottom-center",
            duration: 3000,
          });
          this.loading = false;
        });
      this.loading = false;
    },
    async updateInstantSearch() {
      this.loading = true;
      if (this.instant.is_instant && (this.instant.drivers.length < 1 || this.instant.radius < 1)) {
        this.$toasted.show(this.instant.drivers.length < 1 ? "Select atleast one driver to enable instant trip" : "Radius must be greater than 0", {
          position: "bottom-center",
          duration: 3000,
        });
        this.loading = false;
      } else {
        let request_body = {
          is_instant: this.instant.is_instant,
          modified_by: this.vendorInfo.name,
          branch_id: this.branch_id,
          basic_trip_type: this.trip_type,
          fare_type: this.fare_type_id,
          trip_type: this.trip_sub_type,
          drivers: this.instant.is_instant ? this.instant.drivers : [],
          radius: this.instant.radius,
        };
        const response = await axios.post(this.baseURL + "web/admin/mmt/updateInstantSearch", request_body);
        if (response.data && response.data.success) {
          this.$toasted.show(response.data.message, {
            position: "bottom-center",
            duration: 3000,
          });
          this.getFares();
        } else {
          this.$toasted.show("Error: " + response.data.error, {
            position: "bottom-center",
            duration: 3000,
          });
          this.loading = false;
        }
      }
    },
    getFares() {
      this.loading = true;
      this.fares_list = [];
      axios
        .post(this.baseURL + "web/admin/mmt/getFares", { fare_type: this.fare_type_id })
        .then((res) => {
          if (res.data) {
            console.log("passed");
            this.fares_list = res.data.result;
            this.setFares({ is_new_request: true });
          } else {
            this.$toasted.show(res.data.error, {
              position: "bottom-center",
              duration: 3000,
            });
            this.loading = false;
          }
        })
        .catch((error) => {
          this.$toasted.show(error, {
            position: "bottom-center",
            duration: 3000,
          });
          this.loading = false;
        });
    },
    getYatraTrips() {
      this.trips = [];
      let from_date = this.days[0].text;
      let to_date = this.days[this.days.length - 1].text;
      axios
        .post(this.baseURL + "web/admin/mmt/getYatraTrips", {
          from_date: moment(from_date, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD"),
          to_date: moment(to_date, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD"),
          status: [0, 1, 2, 3, 4, 5, 6, 7, 8],
          corporate_id: 102,
          branch_id: this.branch_id,
        })
        .then((res) => {
          if (res.data) {
            this.trips = res.data.trips;
          } else {
            this.$toasted.show(res.data.error, {
              position: "bottom-center",
              duration: 3000,
            });
          }
        })
        .catch((error) => {
          this.$toasted.show(error, {
            position: "bottom-center",
            duration: 3000,
          });
        });
    },
    setFares(data) {
      this.fares.percentages = [];
      let fares_list = [];
      let fares = [];
      this.fares = [];
      this.updated = {};
      this.days = [];
      if (this.trip_type === 4) {
        let today = moment().endOf("day");
        for (let d = 0; d < 2; d++) {
          const next_day = today.clone().add(d, "days");
          this.days.push({
            text: next_day.format("YYYY-MM-DD HH:mm:ss"),
            kms_slabs: [],
          });
        }
        if (data && data.is_new_request) {
          this.getYatraTrips();
        }
      }
      fares_list = JSON.parse(JSON.stringify(this.fares_list));
      fares = fares_list.find((e) => e.trip_type === this.trip_type && e.branch_id == this.branch_id);
      if (!fares) return (this.is_fare_exist = false);
      if (fares.instant && fares.instant.length > 0) {
        let instant = fares.instant.find((item) => item.id === this.trip_sub_type);
        this.instant = instant ? instant : null;
        this.instant.modified_at = moment(this.instant.modified_at).format("YYYY-MM-DD HH:mm:ss");
      } else {
        this.instant = null;
      }
      fares = fares && fares.fares ? fares.fares.find((e) => e.id === this.trip_sub_type) : null;
      this.hour = fares ? fares.hours : null;
      this.toll = fares ? fares.toll : null;
      this.buffer_time = fares ? fares.buffer_time : null;
      fares = fares && fares.fares ? fares.fares.find((e) => e.id === this.vehicle_type) : null;
      if (!fares) {
        this.loading = false;
        return (this.is_fare_exist = false);
      } else {
        this.updated = { updated_by: fares.modified_by, updated_at: fares.modified_at };
        this.is_fare_exist = true;
      }
      if (this.trip_type === 4) {
        this.days.forEach((day) => {
          let day_data = fares.percentages.find((e) => e.text === day.text);
          if (!day_data) {
            day.kms_slabs = JSON.parse(JSON.stringify(json_data.kms_slabs));
          } else {
            day.kms_slabs = day_data.kms_slabs;
          }
        });
        this.fares = fares;
        this.fares.percentages = this.days;
      } else if (this.trip_type === 2) {
        this.outstation_fares = fares;
      }
      this.loading = false;
    },
    setTripSubTypes() {
      this.trip_sub_types = [];
      this.trip_sub_type = 1;
      this.update_type = 1;
      this.vehicle_type = 1;
      if (this.trip_type === 4) {
        this.label = "Pickup Type";
        this.trip_sub_types = [
          {
            text: "From Airport",
            value: 1,
          },
          {
            text: "To Airport",
            value: 2,
          },
        ];
      } else if (this.trip_type === 2) {
        this.label = "Outstation Type";
        this.trip_sub_types = [
          {
            text: "One Way",
            value: 1,
          },
          {
            text: "Round Trip",
            value: 2,
          },
        ];
      } else {
        this.label = "Package & Slab";
        this.trip_sub_types = [];
      }
      this.setFares();
    },
    async updateFareType() {
      this.fareTypeConfirmation = true;
      this.fare_type_id = this.fare_type;
      this.fare_type_dialog = !this.fare_type_dialog;
      let request_body = {
        fare_type: this.fare_type_id,
        corporate_id: this.corporateId,
        branch_id: this.branch_id,
        modified_by: this.vendorInfo.name,
      };
      const { data } = await axios.post(this.baseURL + "web/admin/mmt/updateFareType", request_body);
      if (data.success === true) {
        this.getFareType();
        this.getFares();
        this.$toasted.show(`Fare Type updated to ${this.fare_type_id === 1 ? "Automatic" : "Manual"}`, {
          position: "bottom-center",
          duration: 3000,
        });
      } else {
        this.$toasted.show(data.error, {
          position: "bottom-center",
          duration: 3000,
        });
      }
    },
    closeDialog() {
      this.fare_type = this.fare_type === 1 ? 2 : 1;
      this.fare_type_dialog = !this.fare_type_dialog;
    },
    async getFareType() {
      const { data } = await axios.post(this.baseURL + "web/admin/mmt/getFareType", {
        corporate_id: this.corporate_id,
        branch_id: this.branch_id,
      });
      if (data.success === true && data.response) {
        this.fare_type = data.response.fare_type;
        this.fare_type_id = this.fare_type;
        this.modified = {
          modified_by: data.response.modified_by,
          modified_at: data.response.modified_at,
        };
        this.getFares();
      }
    },
  },
  //   computed: {
  //     filteredDrivers() {
  //       const search_query = new RegExp(this.search, "i");
  //       return this.drivers.filter((driver) => search_query.test(driver.name) || search_query.test(driver.mobile.toString()));
  //     },
  //   },
  mounted() {
    this.getFareType();
    this.getDrivers();
  },
};
</script>
<style scoped lang="scss">
.label {
  font-size: 0.9rem;
  color: black;
  margin-bottom: 10px;
  font-weight: 700;
}
::v-deep .primary--text {
  color: #eea004 !important;
}
.driver-list {
  ::v-deep .v-list-item {
    padding: 0 5px;
  }
}
.sync-icon {
  color: #eea004;
  margin-left: 20px;
}
.v-icon.v-icon:hover {
  cursor: pointer;
}
.note-section {
  opacity: 0.8;
  display: flex;
  justify-content: flex-end;
  font-size: 0.7rem;
}
.v-card {
  padding: 0px 5px;
}
.vehicle {
  padding: 20px;
  margin-top: 20px;
  width: 100%;
}
.driver-list {
  padding: 5px;
  ::v-deep .v-chip .v-chip__content {
    gap: 10px;
  }
}
.v-list {
  padding: 0px !important;
}
.action-btn {
  display: flex;
  justify-content: center;
  padding: 0px;
  margin-top: 10px;
}
.sub-label {
  font-size: 0.9rem;
  color: black;
  margin-bottom: 10px;
}
.v-card__title:hover {
  cursor: pointer;
}
.fare-container {
  padding: 10px 10px 0px 10px;
  width: 100%;
}
.hourly-restriction {
  padding: 5px 20px 20px 20px;
  width: 100% !important;
  .week-days {
    margin-right: 10px;
  }
}
.combustion-types {
  display: flex;
  gap: 20px;
  .v-input--selection-controls {
    margin-top: 0px;
  }
}
::v-deep .v-list-item__subtitle {
  white-space: wrap !important;
}
::v-deep .v-list-item--active {
  color: #eea004 !important;
}
.slab-percentage {
  padding: 10px 5px;
  .v-card {
    width: 100%;
    padding: 5px;
    margin: 5px 10px;
    .v-card__title {
      padding: 0px 5px;
      display: flex;
      justify-content: space-between;
      .open {
        background-color: black;
        color: #ffffff;
      }
    }
    .sub {
      .sub {
        padding: 2px;
        margin-bottom: 20px;
      }
    }
  }
}
.instant-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.add-fare-dialog {
  .v-card__title {
    padding: 5px 10px;
    background: #eea004;
    color: #ffffff;
  }
}
.arrow {
  rotate: 180deg;
}
.price-info {
  font-size: 0.7rem;
}
.no-fare {
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: center;
  .v-btn {
    margin-top: 5px;
  }
}
.tooltip-label {
  text-align: center;
  text-decoration: underline;
}
.updated-by {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.instant-inventory-card {
  padding: 20px;
  margin: 20px 10px;
  .update-btn {
    display: flex;
    justify-content: center;
    padding: 5px 0px;
  }
}
</style>
