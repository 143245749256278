<template>
  <div class="outer-blk">

      <!-- <v-navigation-drawer app :expand-on-hover="fixed" permanent  color="black" mini-variant-width="60"> -->
      <v-navigation-drawer app expand-on-hover permanent  :class="{ 'open': menuOpen }"  color="black" mini-variant-width="60">
        <div class="tab-content-modal">
          <div class="profile ">
            <div class="profile-bg">
              <div class="profile-user">
              </div>
            </div>
          </div>
        <div class="tab-content">
          <span>{{ vendorName }}</span>
          <span>{{ vendorNumber }}</span>
        </div>
      </div>
      <v-list>
        <!-- Existing menu items -->
        <v-list-group v-for="(item, index) in tabItems" :key="index" v-if="Array.isArray(item.pages)" v-model="item.expanded" color="#eea004">
          <template v-slot:activator>
            <v-list-item-content class="item-page" background-color="red"  :style="{ color: '#ddd'}">
              <div class="title-icon">
                <i class="fa-solid fa-route"></i>
              </div>
              <v-list-item-title :style="{ color: '#ddd'}">{{ item.label }}</v-list-item-title>
            </v-list-item-content>
          </template>
          
          <v-list-item v-for="(page, pageIndex) in item.pages" :key="pageIndex" :style="{ color : selectedTab === page ? '#eea004': '#ddd'}">
            <v-list-item-content @click="selectTab(page)">
              <div class="title-icon">
                <div class="icon-outer">
                  <i class="fa-solid fa-arrow-turn-up sub-list"  height="15px" width="15px"></i>
                </div>
              </div>
              <v-list-item-title>{{ page }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

          <!-- Add 'Driver Management' and 'Vehicle Management' directly -->
          <v-list-item @click="selectTab('Driver Management')" :style="{ color : selectedTab === 'Driver Management' ? '#eea004': '#ddd'}">
          <v-list-item-content class="item-page">
            <div class="title-icon">
              <i class="fa-solid fa-user"></i>
            </div>
            <v-list-item-title>Driver Management</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="selectTab('Vehicle Management')" :style="{ color : selectedTab === 'Vehicle Management' ? '#eea004': '#ddd'}">
          <v-list-item-content class="item-page">
            <div class="title-icon">
              <i class="fa-solid fa-car"></i>
            </div>
            <v-list-item-title>Vehicle Management</v-list-item-title>
            
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="selectTab('MMT Fare')" :style="{ color : selectedTab === 'MMT Fare' ? '#eea004': '#ddd'}" v-if="vendorNumber == 7795777177">
          <v-list-item-content class="item-page">
            <div class="title-icon">
              <i class="fas fa-money-bill-wave"></i>
            </div>
            <v-list-item-title>Fare</v-list-item-title>
            
          </v-list-item-content>
        </v-list-item>

      </v-list>
      <div class="logout-btn-section">
        <v-btn class="logout-button-blk" color="#eea004" @click="logout"><i class="logout-icon"></i><span>Logout</span></v-btn>
      </div>
    </v-navigation-drawer>

    <!-- </div> -->
    <div class="dashboard-content-blk">
      
      <TripList v-if="selectedTab === 'Corporate Trip'"></TripList>
      
      <PersonalTripList v-if="selectedTab === 'Personal Trip'"></PersonalTripList>
      
      <ToBeAssigned v-if="selectedTab === 'Corporate Trip Assign'" @get-all-filters="getFilterData"></ToBeAssigned>
      
      <PersonalTripAssign v-if="selectedTab === 'Personal Trip Assign'" @get-all-filters="getFilterData"></PersonalTripAssign>
      
      <DriverDashboard v-if="selectedTab === 'Driver Management'"></DriverDashboard>
      
      <VehicleDashboard v-if="selectedTab === 'Vehicle Management'"></VehicleDashboard>

      <MMTfare v-if="selectedTab === 'MMT Fare'"></MMTfare>
    </div>
    <!-- <div class="new-trip-modal" v-if="differenceCount > 0"> -->
      <!-- <span @click="reloadData">{{ differenceCount }} New Trips Assigned</span> -->
    <!-- </div> -->

    <Confirm ref="confirm"></Confirm>
  </div>
</template>

<script>
import instance from "@/axios/axios-global";
import ToBeAssigned from "@/components/assign/to-be-assigned/ToBeAssigned";
import DriverDashboard from "@/components/driver-management/driver-dashboard/DriverDashboard";
import TripList from "@/components/Trips/trip-list/TripList";
import VehicleDashboard from "@/components/vehicle-management/vehicle-dashboard/VehicleDashboard";
import PersonalTripList from "@/components/Personal/personal-trip-list/PersonalTripList";
import PersonalTripAssign from "@/components/assign/personal-trip-assign/PersonalTripAssign";
import MMTfare from "@/components/mmt/Fares";
import axios from "@/axios/axios-global";
import * as moment from "moment";
import Confirm from "@/components/common/Confirm";

export default {
  name: "Dashboard",
  components: {Confirm, VehicleDashboard, TripList, DriverDashboard, ToBeAssigned, PersonalTripList, PersonalTripAssign, MMTfare},
  data: () => {
    return {
      menuOpen: false,
      localStorageKey: 'menuOpened',
      status: true,
      tabItems: [
        {
          label: 'Trips',
          pages: ['Corporate Trip', 'Personal Trip'],
          expanded: false,
        },
        {
          label: 'To Be Assigned',
          pages: ['Corporate Trip Assign', 'Personal Trip Assign'],
          expanded: false,
        },
      ],
      drawerState: false,

      selectedTab: 'Corporate Trip Assign',
      tripSelect: '',
      vendorName: '',
      vendorNumber: '', 
      vendorStatus: '',

      filters: '',
      oldTripCount: 0,
      newTripCount: 0,
      differenceCount: 0,
      date: moment().format('YYYY-MM-DD'),
      serverTime: 0,

      // fixed: false,
    }
  },
  async mounted() {
    const menuOpened = localStorage.getItem(this.localStorageKey);
    if (!menuOpened) {
      this.openMenu();
      localStorage.setItem(this.localStorageKey, 'true');
    }

    this.vendorName = localStorage.getItem('vendorName')
    this.vendorNumber = localStorage.getItem('vendorNumber')
    this.vendorStatus = localStorage.getItem('vendorStatus')
    if (this.vendorStatus === '0' || this.vendorStatus === '2') {
      this.selectedTab = 'Account'
    }
    await this.getData({date: this.date})
  },
  methods: {
    openMenu() {
      this.menuOpen = true;
      setTimeout(() => {
        this.closeMenu();
      }, 3000); 
    },
    closeMenu() {
      this.menuOpen = false;
    },

    getFilterData(data) {
      this.filters = data
      if (this.selectedTab === 'To Be Assigned') {
        this.getData(this.filters)
      }

    },
    async getData(data) {
      this.newTripCount = 0
      this.differenceCount = 0
      this.oldTripCount = 0
      this.serverTime = 0
      const response = await axios.post('/backend/tripsToAssign', {
        state: data.state,
        search: data.searchValue,
        city: data.city,
        date: data.date
      })
      this.serverTime = response.data.serverTime
      if (response.data.data.length > 0) {
        this.oldTripCount = response.data.data.length
      }
 
      setInterval(() => {
        if (this.selectedTab !== 'To Be Assigned') {
          this.getTripCount({date: this.date})
        } else {
          if (this.filters !== '') {
            this.getTripCount(this.filters)
          } else {
            this.getTripCount({date: this.date})
          }
        }
      }, 60000)
    },
    async getTripCount(data) {
      const response = await axios.post('/backend/tripCount', {
        state: data.state,
        search: data.searchValue,
        city: data.city,
        date: data.date,
        serverTime: this.serverTime,
      })
      if (response.data.tripCount !== response.data.vendorAssignedTrips) {
        this.newTripCount = response.data.tripCount + response.data.vendorAssignedTrips
      } else if (response.data.tripCount === response.data.vendorAssignedTrips) {
        this.newTripCount = response.data.tripCount
      }
      if ((this.newTripCount - this.oldTripCount) > 0) {
        this.differenceCount = this.newTripCount - this.oldTripCount
      }
    },
    reloadData() {
      this.getData({date: this.date})
      this.selectedTab = 'To Be Assigned'
      if (this.selectedTab !== 'To Be Assigned') {
        this.selectedTab = 'To Be Assigned'
        // this.selectedTab = 'Trip'
        // setInterval(() => {
        //   this.selectedTab = 'To Be Assigned'
        // },0)
      } else {
        this.selectedTab = 'Trip'
        setTimeout(() => {
          this.selectedTab = 'To Be Assigned'
        },0)
      }
    },
    selectTab(data,status) {
      if(!status) {
        this.selectedTab = data
      }
      this.tripSelect = data
      console.log(this.selectedTab + 'sample')
      this.newTripCount = 0
      this.differenceCount = 0
      this.oldTripCount = 0
      this.getData({date: this.date})
    },
    async logout() {
      let confirmTitle = "Confirm Logout";
      let confirmText = "Please Confirm to Logout";
      let confirmIcon = "fas fa-exclamation"
      if (await this.$refs.confirm.open(confirmTitle, confirmText, {
        icon: confirmIcon,
        color: 'red',
        buttonName: 'Logout',
      })) {
        instance.defaults.headers.common['token'] = "";

        localStorage.removeItem('vendorToken');
        localStorage.removeItem('vendorName');
        localStorage.removeItem('vendorNumber');
        localStorage.removeItem('vendorStatus');
        localStorage.removeItem('menuOpened');

        await this.$router.push('/login')
      }
    },
  }
}
</script>

<style scoped lang="scss">
@import "./dashboard.scss";
.open{
  width: 256px!important;
}
.v-list-item{
  border-bottom: 0.2px dotted  #434343;
}
.v-list-group{
  border-bottom: 0.2px dotted  #434343;
}
.item-page{
  display: contents!important;
  .title-icon{
      max-width: 20px;
      max-height: 20px;
      margin-right: 8px;
    // img{
    //   max-width: 40px;
    //   max-height: 40px;
    //   font-size: 40px;
    // }
  }
  
}
.sub-list{
rotate: 90deg;
}
.fa-solid{
  font-size: larger;
}

.v-list-item__content{
  margin-left: 30px!important;
  display: contents!important;
  cursor: pointer;
  .title-icon{
    max-width: 20px;
    margin-right: 10px;
    .sub-list{
    margin-left: 5px;
    }
    .icon-outer{
      display: flex;
    }
    }
}
.tab-inner-blk{
  :hover {
          color: white;
        }
  
        .selected-tab-blk {
          color: white;
        }
}
.profile{
  display: flex;
    margin-bottom: 9px;
}
.profile-bg{
    background-color: #eea004;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin-left: 5px;
    display: flex;
}
.profile-user{
  width: 32px;
  height: 32px;
  padding: 10px;
  background: url(../../assets/assets/images/user-solid.svg) no-repeat center center;
}
.logout-btn-section{
  position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    background: #eea004!important;
    button{
    width: 100%;
    height: auto !important;
    float: left;
    height: auto;
    padding: 10px 15px !important;
    outline: none;
    border: none !important;
    text-align: left;
    color: #ddd;
    font-weight: 600;
    background: none;
    border-radius: 0;
    font-size: 14px;
    text-transform: capitalize;
    }
    i{
      width: 25px;
      height: 25px;
      float: left;
      background: #000;
      margin-right: 5px;
      background: url(../../assets/assets/images/signout_normal.png) no-repeat center center;
      background-size: cover;

    }

    .v-btn__content span {
      // @include transition(all .3s ease-in-out);
      opacity: 0;
      position: absolute;
    }
  //   span{
  //   display: none;
  // }
}

.v-navigation-drawer--is-mouseover .logout-btn-section .logout-button-blk .v-btn .btn-label {
  opacity: 1

}

.v-navigation-drawer--is-mouseover .logout-btn-section .logout-button-blk button .v-btn__content span {
  opacity: 1;
  position: relative;
}
// .v-navigation-drawer--is-mouseover{
//   .signout-outerblk span{
//     display: block!important;
//   }
// }
.tab-content-modal{
    display: flex;
    gap: 10px;
    border-bottom: 0.5px solid #fff;
  margin-top: 5px!important;
}
.tab-content{
    display: flex;
    flex-direction: column;
  min-width: 164px;
  span{
    color: #fff;
  }
}

.tab-content-blk {
  position: relative;

  :hover {
          color: white;
        }
  
        .selected-tab-blk {
          color: white;
        }

  &.dropdown {
    .dropdown-content {
      display: none;
      position: absolute;
      background-color: #f9f9f9;
      min-width: 160px;
      box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
      z-index: 999;

      span {
        padding: 12px;
        display: block;
        color: black          ;

        &:hover {
          background-color: #ddd;
        }

      }
    }

    &.selected-tab-blk .dropdown-content {
      display: block !important; 
      // z-index: 99999;
    }
  }
}


</style>